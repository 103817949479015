var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"big-box",style:({ width: _vm.bigBoxWidth, height: _vm.bigBoxHeight })},[(_vm.bannerList.length)?_c('div',{staticClass:"img-box"},_vm._l((_vm.bannerList),function(item,index){return _c('div',{key:item.img,staticClass:"show-box",style:({
          transform: 'translateX(' + _vm.translate + '%)',
          transition: _vm.tsion ? 'all 0.5s' : 'none',
        })},[_c('img',{style:({ width: _vm.bigBoxWidth }),attrs:{"src":item.img,"alt":""},on:{"click":function($event){return _vm.jump(item.url)}}}),_c('div',{staticClass:"show-box-text",style:({ top: item.top, left: item.left })},[_vm._l((item.title),function(i){return _c('h1',{key:i,style:({
              marginTop: index == 1 ? '-26px' : '',
              color: _vm.types == 'product' ? '#12A480' : '',
            })},[_vm._v(" "+_vm._s(i)+" ")])}),_vm._l((item.list),function(k){return _c('div',{key:k,staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(k)+" ")])}),(index == 4)?_c('div',{staticStyle:{"display":"flex","font-size":"26px","font-weight":"500"}},[_c('div',{staticStyle:{"writing-mode":"vertical-rl"}},[_vm._v("优选好材真材实料")]),_c('div',{staticStyle:{"writing-mode":"vertical-rl"}},[_vm._v("好品质才有好效果")])]):_vm._e()],2)])}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNext),expression:"showNext"}],staticClass:"arrowhead-box"},[_c('span',{on:{"click":_vm.last}}),_c('span',{on:{"click":_vm.next}})]),_c('div',{ref:"swiper",staticClass:"swiper-box"},_vm._l((_vm.bannerList),function(item,index){return _c('span',{key:index,on:{"click":function($event){return _vm.swiper(index)}}})}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }