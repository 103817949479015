<template>
  <div id="app">
    <!-- <daohang> </daohang> -->

    <router-view></router-view>
    <!-- <dibudaohang> </dibudaohang> -->
  </div>
</template>

<script>
// import daohang from "./components/index/daohang.vue";
// import dibudaohang from "./components/dibudaohang/dibudaohang";

export default {
  name: "App",
  components: {
    // daohang,
    // dibudaohang
  },
};
</script>

<style>
body {
  margin: 0;
  overflow-x: hidden;
}

.subMain {
  width: 70%;
  margin: 30px auto;
}

.subTitle {
  display: flex;
  border-bottom: 1px solid #12a480;
  margin: 50px 0;
}
.subTitle div {
  width: 94px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}
.subTitle-active {
  background: #12a480;
  color: #fff;
}
.curosr {
  cursor: pointer;
}
</style>
