<template>
  <div>
    <!-- ---------- -->

    <div class="footer_warp">
      <div class="footer_cont">
        <div class="footer_cont_l">
          关注我们
          <img src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/wx.png" alt="" />
          <img src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/wb.png" alt="" />
        </div>
        <div class="footer_cont_l">
          <a
            style="color: #fff"
            href="https://jiugongai.tmall.com/"
            target="_blank"
            >电商链接：天猫旗舰店</a
          >
        </div>
      </div>
      <div class="footer_warp_btm">
        <div>
          <span
            class="curosr"
            @click="funyuming(item.url)"
            v-for="(item, index) in navList"
            :key="index"
            >{{ item.title }}</span
          >
        </div>
        <div style="margin: 50px 0 20px">
          九宫艾（深圳）科技集团有限公司版权所有 全国服务热线：0755-86961543
        </div>
        <div>
          <a
            style="color: #fff"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >粤ICP备2021028643号</a
          >
        </div>
      </div>
    </div>

    <!-- --------- -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      navList: [
        { title: "首页", url: "/" },
        {
          title: "走进九宫艾",
          url: "/zj/0",
          child: [
            { title: "品牌简介", url: "/zj/0" },
            { title: "品牌文化", url: "/zj/1" },
            { title: "品牌实力", url: "/zj/2" },
          ],
        },
        { title: "制艾工艺", url: "/zhizuo" },
        { title: "产品中心", url: "/product" },
        {
          title: "品牌体系",
          url: "/pptx/0",
          child: [
            { title: "线下门店", url: "/pptx/0" },
            { title: "线上商城", url: "/pptx/1" },
            { title: "健康学院", url: "/pptx/2" },
          ],
        },
        {
          title: "品牌动态",
          url: "/ppdt/0",
          child: [
            { title: "企业动态", url: "/ppdt/0" },
            { title: "行业动态", url: "/ppdt/1" },
          ],
        },
        { title: "联系我们", url: "/contact" },
      ],
    };
  },

  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },
  methods: {
    funyuming(msg) {
      this.$router.push(msg);
    },
    // 锚点跳转
    toGuid(path, id) {
      localStorage.setItem("toId", id);
      this.$router.push(path);
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },

    toPages(url) {
      this.$router.push(url);
    },
    toNews(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
.footer_warp {
  width: 100vw;
  height: 388px;
  color: #d9dada;
  background: #17213d;
}
.footer_cont {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footer_cont_l {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding-top: 77px;
}
.footer_cont_l img {
  width: 35px;
  height: 29px;
  margin-left: 44px;
}
.footer_cont_c {
  margin-top: 70px;
  margin-left: 80px;
}
.footer_warp_btm {
  padding: 20px 0;
  width: 1280px;
  margin: 0 auto;
  height: 65px;
  margin-top: 56px;
  color: #d9dada;
  border-top: 1px solid #444444;
}
.footer_warp_btm span {
  margin-right: 40px;
}
</style>
