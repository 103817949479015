import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload";
Vue.config.productionTip = false;
Vue.use(VueLazyload, {
	preLoad: 1.3,
	loading: require("./assets/loading.gif"),
	attempt: 1
});
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

// title

// title
